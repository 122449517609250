<template>
  <div class="container">
    <header>
      <div class="top">
        <van-icon
          name="arrow-left"
          size="20"
          color="#fff"
          @click="$router.go(-1)"
        />
      </div>
      <div class="nav">
        <div class="left">
          <span
            :class="{ active: active === index }"
            @click="active = index"
            v-for="(item, index) in navArr"
            :key="item"
          >
            {{ item }}
          </span>
        </div>
        <div class="right" v-show="active === 0">
          <span @click="showActions = true"
            >{{ actions[activeSelect] }} <img src="@/assets/new/pull.png" alt=""
          /></span>
        </div>
      </div>
    </header>
    <div class="content">
      <NoData v-if="list.length === 0" />
      <van-list
        class="list-box"
        ref="list"
        v-else
        v-model="loading"
        :finished="finished"
        loading-text=""
        finished-text=""
        @load="getList"
      >
        <div class="info" v-for="(item, index) in list" :key="index">
          <div class="name">
            <div class="type">
              <span>{{ $t('trade')[item.BuyDirection] }}</span>
              <span> {{ item.ProductName }}/USDT</span>
            </div>
            <div class="orderNum">
              {{ $t('trade').orderNo }}： {{ item.OrderNo }}
            </div>
          </div>

          <div class="line">
            <div class="item" v-if="active === 0">
              <div>{{ $t('trade').weituojia }}</div>
              <div>{{ item.StartPrice }}</div>
            </div>
            <div class="item" v-else>
              <div>{{ $t('trade').chengjiaojia }}</div>
              <div>{{ item.EndPrice }}</div>
            </div>
            <div class="item">
              <div>{{ $t('trade').shuliang }}</div>
              <div>{{ item.Num }}</div>
            </div>
            <div class="item">
              <div>{{ $t('trade').chengjiaoe }}</div>
              <div>{{ item.UsdtAmount }}</div>
            </div>
          </div>
          <div class="line">
            <div class="item">
              <div>{{ $t('trade').shijian }}</div>
              <div class="time">{{ item.CreateTime | getLocalTime }}</div>
            </div>
            <div class="item">
              <div>{{ $t('trade').weituo }}</div>
              <div>
                {{
                  item.OrderType === 'Limit' ? $t('trade').xj : $t('trade').sj
                }}
              </div>
            </div>
            <div class="item" v-if="active === 0">
              <div>{{ $t('trade').caozuo }}</div>
              <div class="btn" @click="handleCancle(item.OrderNo)">
                {{ $t('public').cancel }}
              </div>
            </div>
            <div class="item" v-else></div>
          </div>
        </div>
      </van-list>
    </div>
    <!-- <SelectBox
      :cancleMode="true"
      :actions="actions"
      :activeSelect="activeSelect"
      v-if="showActions"
      @close="showActions = false"
      @select="handleSelect"
    /> -->
    <van-popup class="popup" v-model="showActions" position="bottom">
      <van-picker
        show-toolbar
        :columns="actions"
        @confirm="handleSelect"
        @cancel="showActions = false"
        :confirm-button-text="$t('public').sure"
        :cancel-button-text="$t('public').cancel"
      />
    </van-popup>
  </div>
</template>

<script>
// import SelectBox from '@/components/selectBox'
import NoData from '@/components/noData'
import productApi from '@/api/product'
export default {
  components: {
    // SelectBox,
    NoData
  },
  data() {
    return {
      list: [],
      active: 0,
      activeSelect: 0,
      actions: [this.$t('trade').Buy, this.$t('trade').Sell],
      navArr: [this.$t('trade').qbwt, this.$t('trade').lswt],
      showActions: false,
      loading: false,
      finished: false,
      form: {
        page: 1,
        size: 10,
        orderStatus: null,
        buyDirection: null
      }
    }
  },
  watch: {
    active() {
      this.reset()
    },
    activeSelect() {
      this.reset()
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async handleCancle(OrderNo) {
      await productApi.cancelCoinOrder(OrderNo)
      this.$toast(this.$t('moneyAddr').success)
      setTimeout(() => {
        this.reset()
      }, 500)
    },
    reset() {
      this.form.page = 1
      this.list = []
      this.finished = false
      this.getList()
    },
    handleSelect(index, value) {
      this.showActions = false
      this.activeSelect = value
    },
    async getList() {
      let form = { ...this.form }
      if (this.active) {
        form.orderStatus = 'Complete'
      } else {
        form.orderStatus = 'Create'
        form.buyDirection = this.activeSelect ? 'Sell' : 'Buy'
      }
      const res = await productApi.CoinAllRecord(form)
      let data = res.Data
      let length = data.length
      if (length != 0) {
        this.list.push.apply(this.list, data)
        this.form.page++
      }
      if (length !== this.form.size) {
        this.finished = true
      }
      if (this.refreshing) {
        this.refreshing = false
      }
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  color: #7286a5;
  padding: 0 20%;
  .popup {
    ::v-deep .van-picker {
      background: #020406;
      .van-picker__toolbar {
        background: #010203;
      }
    }
    ::v-deep .van-picker-column__item {
      color: #fff !important;
    }
    ::v-deep .van-picker-column__item--selected {
      font-size: 20px;
      font-weight: bold;
    }
    ::v-deep .van-picker__mask {
      background: rgba(0, 0, 0, 0.5);
    }
  }
  header {
    user-select: none; 
    background: #17181e;
    .top {
      display: flex;
      align-items: center;
      height: 44px;
      padding-left: 10px;
    }
    .nav {
      display: flex;
      padding: 0 10px;
      justify-content: space-between;
      align-items: center;
      height: 43px;
      font-size: 14px;
      .left {
        span {
          margin-right: 10px;
        }
        .active {
          font-size: 18px;
        }
      }
      .right {
        display: flex;
        align-items: center;
        img {
          width: 10px;
          height: 10px;
        }
      }
    }
  }
  .content {
    font-size: 14px;
    .list-box {
      height: calc(100vh - 88px);
      overflow: scroll;
    }
    .info {
      background: #17181e;
      margin-top: 10px;
      padding: 10px;
      .name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .type {
          font-size: 16px;
          span {
            margin-right: 5px;
            &:nth-of-type(2) {
              font-weight: bold;
              color: #fff;
            }
          }
        }
      }
      .line {
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .item {
          line-height: 1.5;
          flex: 1;
          &:nth-of-type(2) {
            text-align: center;
          }
          &:nth-of-type(3) {
            text-align: right;
          }
          div:nth-of-type(2) {
            color: #e9e9e9;
          }
          .btn {
            border: 1px solid #d74e5a;
            padding: 1px 3px;
            display: inline-block;
            color: #d74e5a !important;
          }
        }
      }
    }
  }
}
</style>
